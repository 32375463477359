"use client";

import { useMemo } from "react";
import Link from "next/link";

import Button from "@/components/atoms/Button";
import DrupalImage from "@/components/atoms/DrupalImage";
import {
  ImageStyleAvailable,
  ThreeColumnBlockParagraphFieldsFragment,
} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { getVariation } from "@/helpers/image";

interface ThreeColumnBlockParagraphProps {
  className?: string;
  data: ThreeColumnBlockParagraphFieldsFragment;
}

export const ThreeColumnBlockParagraph = ({
  data,
  className,
}: ThreeColumnBlockParagraphProps) => {
  const imageForBlock1 = useMemo(() => {
    if (
      !data.paragraphThreeColumnBlockImageForBlock1?.imageMediaImage?.variations
    ) {
      return null;
    }
    return getVariation(
      data.paragraphThreeColumnBlockImageForBlock1.imageMediaImage.variations,
      ImageStyleAvailable.W2000,
    );
  }, [
    data.paragraphThreeColumnBlockImageForBlock1?.imageMediaImage?.variations,
  ]);

  const imageForBlock2 = useMemo(() => {
    if (
      !data.paragraphThreeColumnBlockImageForBlock2?.imageMediaImage?.variations
    ) {
      return null;
    }
    return getVariation(
      data.paragraphThreeColumnBlockImageForBlock2.imageMediaImage.variations,
      ImageStyleAvailable.W2000,
    );
  }, [
    data.paragraphThreeColumnBlockImageForBlock2?.imageMediaImage?.variations,
  ]);

  const imageForBlock3 = useMemo(() => {
    if (
      !data.paragraphThreeColumnBlockImageForBlock3?.imageMediaImage?.variations
    ) {
      return null;
    }
    return getVariation(
      data.paragraphThreeColumnBlockImageForBlock3.imageMediaImage.variations,
      ImageStyleAvailable.W2000,
    );
  }, [
    data.paragraphThreeColumnBlockImageForBlock3?.imageMediaImage?.variations,
  ]);

  return (
    <div className={cn(className, "paragraph--three-column-block container")}>
      <div className="grid grid-cols-12">
        <div className="col-span-12 xl:col-span-8 xl:col-start-3">
          {data.label ? (
            <h5 className="caption !mb-8 text-center !text-sm text-orange lg:!mb-12">
              {data.label}
            </h5>
          ) : null}

          {data.title ? (
            <h2 className="!mb-8 text-center lg:!mb-12">{data.title}</h2>
          ) : null}

          <div className="flex flex-col gap-y-12 lg:flex-row lg:gap-x-8">
            <div className="flex basis-1/3 flex-col gap-y-8 overflow-hidden">
              {imageForBlock1 && (
                <DrupalImage
                  url={imageForBlock1.url}
                  alt={
                    data.paragraphThreeColumnBlockImageForBlock1
                      ?.imageMediaImage?.alt ?? ""
                  }
                  width={imageForBlock1.width}
                  height={imageForBlock1.height}
                  className={"w-full rounded-2xl"}
                />
              )}

              {data.labelForBlock1 && (
                <div className="h5 caption !mb-0 !text-sm text-orange">
                  {data.labelForBlock1}
                </div>
              )}

              {data.paragraphThreeColumnBlockTitleForBlock1 && (
                <h4 className="!mb-0 overflow-hidden text-ellipsis">
                  {data.paragraphThreeColumnBlockTitleForBlock1}
                </h4>
              )}

              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: data.paragraphThreeColumnBlockTextForBlock1
                    .processed as string,
                }}
              />

              {data.linksForBlock1 && (
                <div className="mt-auto flex flex-wrap items-center gap-6 lg:gap-8">
                  {data.linksForBlock1.map((link, index) =>
                    index === 0 ? (
                      <Button
                        as="link"
                        key={link.url}
                        href={link.url}
                        arrow={data.linksForBlock1.length === 1}
                        className="btn-lg"
                      >
                        {link.title}
                      </Button>
                    ) : (
                      <Link key={link.url} href={link.url} className="link">
                        {link.title}
                      </Link>
                    ),
                  )}
                </div>
              )}
            </div>
            <div className="flex basis-1/3 flex-col gap-y-8 overflow-hidden">
              {imageForBlock2 && (
                <DrupalImage
                  url={imageForBlock2.url}
                  alt={
                    data.paragraphThreeColumnBlockImageForBlock2
                      ?.imageMediaImage?.alt ?? ""
                  }
                  width={imageForBlock2.width}
                  height={imageForBlock2.height}
                  className={"w-full rounded-2xl"}
                />
              )}

              {data.labelForBlock2 && (
                <div className="h5 caption !mb-0 !text-sm text-orange">
                  {data.labelForBlock2}
                </div>
              )}

              {data.paragraphThreeColumnBlockTitleForBlock2 && (
                <h4 className="!mb-0 overflow-hidden text-ellipsis">
                  {data.paragraphThreeColumnBlockTitleForBlock2}
                </h4>
              )}

              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: data.paragraphThreeColumnBlockTextForBlock2
                    .processed as string,
                }}
              />

              {data.linksForBlock2 && (
                <div className="mt-auto flex flex-wrap items-center gap-6 lg:gap-8">
                  {data.linksForBlock2.map((link, index) =>
                    index === 0 ? (
                      <Button
                        as="link"
                        key={link.url}
                        href={link.url}
                        arrow={data.linksForBlock2.length === 1}
                        className="btn-lg"
                      >
                        {link.title}
                      </Button>
                    ) : (
                      <Link key={link.url} href={link.url} className="link">
                        {link.title}
                      </Link>
                    ),
                  )}
                </div>
              )}
            </div>
            <div className="flex basis-1/3 flex-col gap-y-8 overflow-hidden">
              {imageForBlock3 && (
                <DrupalImage
                  url={imageForBlock3.url}
                  alt={
                    data.paragraphThreeColumnBlockImageForBlock3
                      ?.imageMediaImage?.alt ?? ""
                  }
                  width={imageForBlock3.width}
                  height={imageForBlock3.height}
                  className={"w-full rounded-2xl"}
                />
              )}

              {data.labelForBlock3 && (
                <div className="h5 caption !mb-0 !text-sm text-orange">
                  {data.labelForBlock3}
                </div>
              )}

              {data.paragraphThreeColumnBlockTitleForBlock3 && (
                <h4 className="!mb-0 overflow-hidden text-ellipsis">
                  {data.paragraphThreeColumnBlockTitleForBlock3}
                </h4>
              )}

              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: data.paragraphThreeColumnBlockTextForBlock3
                    .processed as string,
                }}
              />

              {data.linksForBlock3 && (
                <div className="mt-auto flex flex-wrap items-center gap-6 lg:gap-8">
                  {data.linksForBlock3.map((link, index) =>
                    index === 0 ? (
                      <Button
                        as="link"
                        key={link.url}
                        href={link.url}
                        arrow={data.linksForBlock3.length === 1}
                        className="btn-lg"
                      >
                        {link.title}
                      </Button>
                    ) : (
                      <Link key={link.url} href={link.url} className="link">
                        {link.title}
                      </Link>
                    ),
                  )}
                </div>
              )}
            </div>
          </div>

          {data.links && (
            <div className="mt-12 flex flex-wrap items-center gap-6 lg:justify-center">
              {data.links.map((link, index) =>
                index === 0 ? (
                  <Button
                    as="link"
                    key={link.url}
                    href={link.url}
                    arrow={data.links.length === 1}
                    className="btn-lg"
                  >
                    {link.title}
                  </Button>
                ) : (
                  <Link key={link.url} href={link.url} className="link">
                    {link.title}
                  </Link>
                ),
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
