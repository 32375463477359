'use client';

import DrupalImage from "@/components/atoms/DrupalImage";
import {
  ImageFieldsFragment,
  ImageStyleAvailable, MediaTextParagraphFieldsFragment,
} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { getVariation } from "@/helpers/image";
import Button from "@/components/atoms/Button";
import Link from "next/link";
import {useRef} from "react";
import dynamic from "next/dynamic";
import PlayBtn from "@/components/atoms/PlayBtn";

interface MediaTextParagraphProps {
  className?: string;
  data: MediaTextParagraphFieldsFragment;
}

export const MediaTextParagraph = ({ data, className }: MediaTextParagraphProps) => {
  const imageLeft = data.entity_type_behaviors?.media_position === "left";
  const textWidth33 = data.entity_type_behaviors?.text_width === "33";
  const textWidth50 = data.entity_type_behaviors?.text_width === "55";
  const textWidth66 = data.entity_type_behaviors?.text_width === "66";
  let videoUrl = '';
  let posterUrl = '';
  const playerRef = useRef();

  if(data.paragraphMediaTextMedia?.mediaOembedVideo) {
    videoUrl = data.paragraphMediaTextMedia.mediaOembedVideo;
    const videoId = videoUrl.includes("embed/")
      ? videoUrl.split("embed/")[1]
      : videoUrl.split("v=")[1];

    posterUrl = data.paragraphMediaTextMedia.remoteVideoMediaImage?.variations
      ? getVariation(
        data.paragraphMediaTextMedia.remoteVideoMediaImage.variations,
        ImageStyleAvailable.W3800,
      ).url
      : `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
  }

  const VideoPlayerNoSSR = dynamic(
    () => import("@/components/atoms/VideoPlayer"),
    { ssr: false },
  );

  return (
    <div className={cn(className, "paragraph--media-text container")}>
      <div className={cn(
          "flex flex-col lg:flex-row",
          !imageLeft && "lg:flex-row-reverse")
        }
      >
        <div
          className={cn(
            "flex flex-col basis-1/2 justify-center mb-8 lg:mb-0",
            textWidth33 && "basis-2/3",
            textWidth50 && "basis-1/2",
            textWidth66 && "basis-1/3"
          )}
        >
          {videoUrl ? (
            <div className="aspect-video w-full rounded-2xl overflow-hidden">
              <VideoPlayerNoSSR
                playerRef={playerRef}
                playBtn={<PlayBtn/>}
                videoUrl={videoUrl}
                posterUrl={posterUrl || undefined}
              />
            </div>
          ) : (
            <MediaImage data={data}/>
          )}
        </div>
        <div className={cn(
              "flex flex-col basis-1/2 justify-center lg:p-2 lg:px-8 xl:px-12 2xl:px-16",
              textWidth33 && "basis-1/3",
              textWidth50 && "basis-1/2",
              textWidth66 && "basis-2/3"
            )}
        >
          {data.label && <div className="h4 !text-sm caption text-orange">{data.label}</div>}
          {data.title && <h2>{data.title}</h2>}

          <div
            className="prose"
            dangerouslySetInnerHTML={{
              __html: data.paragraphMediaTextText.processed as string,
            }}
          />

          {data.links && (
            <div className="flex flex-wrap items-center gap-6 lg:gap-8 mt-8 lg:mt-12">
              {data.links.map((link, index) => (
                (index === 0) ? (
                  <Button
                    as='link'
                    key={link.url}
                    href={link.url}
                    arrow={data.links.length === 1}
                    className="btn-lg"
                  >
                    {link.title}
                  </Button>
                ) : (
                  <Link
                    key={link.url}
                    href={link.url}
                    className="link"
                  >
                    {link.title}
                  </Link>
                )
              ))}
            </div>
          )}
          </div>
      </div>
    </div>
  )
};


interface MediaImageProps {
  data: MediaTextParagraphFieldsFragment
}
const MediaImage = ({ data }: MediaImageProps) => {
  const paragraphMediaTextMedia = data.paragraphMediaTextMedia
  const paragraphMediaTextLinkForMedia = data.paragraphMediaTextLinkForMedia

  if (!paragraphMediaTextMedia ||
    !("imageMediaImage" in paragraphMediaTextMedia) ||
    !paragraphMediaTextMedia.imageMediaImage ||
    !paragraphMediaTextMedia.imageMediaImage?.variations
  ) {
    return null
  }


  const mediaImage: ImageFieldsFragment = paragraphMediaTextMedia.imageMediaImage
  const image = getVariation(paragraphMediaTextMedia.imageMediaImage.variations, ImageStyleAvailable.W2000)

  if(!image) {
    return null
  }

  const imageElement = (
    <DrupalImage
      url={image.url}
      alt={mediaImage?.alt ?? ''}
      width={image.width}
      height={image.height}
      className={"w-full rounded-2xl"}
    />
  )

  const linkURL = paragraphMediaTextLinkForMedia?.url
  if (linkURL) {
    return (
      <Link href={linkURL} target={paragraphMediaTextLinkForMedia?.internal ? '_self': '_blank'}>
        {imageElement}
      </Link>
    )
  }

  return (
    <>
      {imageElement}
    </>
  )
}
